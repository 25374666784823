import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { firstMenu } from '@/utils/map-menus'
// import store from '@/store'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    name: 'login'
  },
  {
    path: '/main',
    component: () => import('@/views/main/main.vue'),
    name: 'main',
    children: []
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/not-fount/not-fount.vue'),
    name: 'not-fount'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')

  if (to.path !== '/login' && !token) {
    return next('/login')
  } else if (token && to.path === '/main') {
    next(firstMenu.url)
  } else {
    next()
  }
})

export default router
