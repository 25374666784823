import myRequest from '@/servise'
import { IDataType } from '@/servise/types'

export function getPageListData(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

//查找工程师排班
export function getEngineerSchedules(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

//获取工程师当日预约情况
export function getAppointmentStatus(url: string, params: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: params
  })
}

//批量排班
export function batchSchedule(url: string, params: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: params
  })
}

//添加或编辑今日排班
export function dailySchedule(url: string, params: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: params
  })
}
