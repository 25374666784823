import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { IHomeState } from './types'
import {
  getPageListData,
  deletePageData,
  createPageData,
  editPageData,
  processPageData,
  sendOutGoods
} from '@/servise/main/home/home'
import LocalCache from '@/utils/cache'

const homeModule: Module<IHomeState, IRootState> = {
  namespaced: true,
  state() {
    return {
      imageGroupsList: [],
      imageGroupsCount: 0,
      userList: [],
      userCount: 0,
      videoList: [],
      videoCount: 0,
      productList: [],
      productCount: 0,
      processList: [],
      processCount: 0,
      exchangeRecordsList: [],
      exchangeRecordsCount: 0,
      lessonList: [],
      lessonCount: 0,
      lessonOrdersList: [],
      lessonOrdersCount: 0,
      engineerList: [],
      engineerCount: 0,
      appointmentsList: [],
      appointmentsCount: 0
    }
  },
  mutations: {
    changeImageGroupsList(state, list: any[]) {
      state.imageGroupsList = list
    },
    changeImageGroupsCount(state, count: number) {
      state.imageGroupsCount = count
    },
    changeUserList(state, list: any[]) {
      state.userList = list
    },
    changeUserCount(state, count: number) {
      state.userCount = count
    },
    changeVideoList(state, list: any[]) {
      state.videoList = list
    },
    changeVideoCount(state, count: number) {
      state.videoCount = count
    },
    changeProductList(state, list: any[]) {
      state.productList = list
    },
    changeProductCount(state, count: number) {
      state.productCount = count
    },
    changeProcessList(state, list: any[]) {
      state.processList = list
    },
    changeProcessCount(state, count: number) {
      state.processCount = count
    },
    changeExchangeRecordsList(state, list: any[]) {
      state.exchangeRecordsList = list
    },
    changeExchangeRecordsCount(state, count: number) {
      state.exchangeRecordsCount = count
    },
    changeLessonList(state, list: any[]) {
      state.lessonList = list
    },
    changeLessonCount(state, count: number) {
      state.lessonCount = count
    },
    changeLessonOrdersList(state, list: any[]) {
      state.lessonOrdersList = list
    },
    changeLessonOrdersCount(state, count: number) {
      state.lessonOrdersCount = count
    },
    changeEngineerList(state, list: any[]) {
      state.engineerList = list
    },
    changeEngineerCount(state, count: number) {
      state.engineerCount = count
    },
    changeAppointmentsList(state, list: any[]) {
      state.appointmentsList = list
    },
    changeAppointmentsCount(state, count: number) {
      state.appointmentsCount = count
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'List']
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'Count']
      }
    }
  },
  actions: {
    //获取数据
    async getPageListAction({ commit }, payload: any) {
      //1、获取pageUrl
      const pageName = payload.pageName
      console.log(payload)
      let pageUrl = ''
      switch (pageName) {
        case 'imageGroups':
          pageUrl = '/imgGroup/getImgGroup'
          break
        case 'process':
          pageUrl = '/imgGroup/getImgGroup'
          break
        case 'video':
          pageUrl = '/live/findVideoList'
          break
        case 'product':
          pageUrl = '/live/findProductList'
          break
        case 'exchangeRecords':
          pageUrl = '/order/findPointsOrder'
          break
        case 'lesson':
          pageUrl = '/lesson/findLessonList'
          break
        case 'lessonOrders':
          pageUrl = '/lessonOrder/findLessonOrder'
          break
        case 'engineer':
          pageUrl = '/common/findList'
          break
        case 'appointments':
          pageUrl = 'reservation/findReservationList'
          break
        case 'user':
          pageUrl = ''
          break
      }

      if (pageName === 'user') {
        const list = LocalCache.getCache('userMenus')
        commit(`changeUserList`, list)
        commit(`changeUserCount`, 1)
      } else {
        //2、对页面发送网络请求
        const pageResult = await getPageListData(pageUrl, payload.queryInfo)
        console.log(pageResult)

        //3、将请求结果赋值给state
        const { list, totalCount } = pageResult.data

        const changePageName =
          pageName.slice(0, 1).toUpperCase() + pageName.slice(1)

        commit(`change${changePageName}List`, list)
        commit(`change${changePageName}Count`, totalCount)
      }
    },
    //删除数据
    async deletePageDataAction({ dispatch }, payload: any) {
      const { pageName, objectId, isShow } = payload
      const className = pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
      const pageUrl = '/common/removeById'
      //1、调用删除网络请求
      await deletePageData(pageUrl, { className, objectId })

      //2、重新请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10,
          isShow
        }
      })
    },
    //新建
    async createPageDataAction({ dispatch }, payload: any) {
      //1、创建数据请求
      const { pageName, params, isShow } = payload
      console.log(params)

      const className = pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
      let pageUrl = ''
      switch (pageName) {
        case 'imageGroups':
          pageUrl = '/common/insert'
          break
        case 'engineer':
          pageUrl = '/common/insert'
          break
        case 'user':
          pageUrl = ''
          break
      }
      const results = await createPageData(pageUrl, { className, params })
      console.log(results)

      //2、请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10,
          isShow
        }
      })
    },
    //编辑
    async editPageDataAction({ dispatch }, payload: any) {
      // 1、编辑数据请求
      const { pageName, editData, objectId } = payload
      console.log(editData)

      const className = pageName.slice(0, 1).toUpperCase() + pageName.slice(1)

      let pageUrl = ''
      switch (pageName) {
        case 'imageGroups':
          pageUrl = '/common/updateById'
          break
        case 'exchangeRecords':
          pageUrl = '/common/updateById'
          break
        case 'engineer':
          pageUrl = '/common/updateById'
          break
        case 'appointments':
          pageUrl = '/common/updateById'
          break
        case 'user':
          pageUrl = ''
          break
        default:
          throw new Error(`Unsupported page name: ${pageName}`)
      }

      await editPageData(pageUrl, { objectId, className, params: editData })

      // 2、请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10,
          status: payload.status,
          className
        }
      })
    },
    //发货
    async deliverPageDataAction({ dispatch }, payload: any) {
      //1、编辑数据请求
      const { objectId, params, isShow } = payload

      await sendOutGoods('/order/sendOutGoods', { objectId, params })
      //2、请求最新数据
      dispatch('getPageListAction', {
        queryInfo: {
          page: 0,
          size: 10,
          isShow
        }
      })
    },
    //审核案例
    async processPageDataAction({ dispatch }, payload: any) {
      const { objectId } = payload
      const pageUrl = '/imgGroup/processPageData'
      const pageName = 'imageGroups'
      //1、调用审核网络请求
      const results = await processPageData(pageUrl, { objectId })
      console.log(results)

      //2、重新请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10,
          isShow: false
        }
      })
    }
  }
}

export default homeModule
